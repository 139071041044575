<template>
  <v-card class="no-padding">
    <v-card-title>Choix de la méthode d'envoi de notification</v-card-title>
    <v-card-subtitle>Deux choix s'offre à vous :</v-card-subtitle>
    <v-card-text class="align-center justify-center text-center">
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-card outlined color="rgba(0, 31, 71, 0.3)">
            <v-card-title class="EoleBlue--text text-center justify-center">Par mail</v-card-title>
            <v-card-text class="EoleBlue--text">
              <v-icon size="32">mdi-check</v-icon>
            </v-card-text>
            <v-card-subtitle class="EoleBlue--text">{{ email }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card outlined>
            <v-card-title class="EoleBlue--text text-center justify-center">Par téléphone</v-card-title>
            <v-card-text class="EoleBlue--text">
              <v-icon size="32">mdi-close</v-icon>
            </v-card-text>
            <v-card-subtitle class="EoleBlue--text">{{ phone }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-list>
      <v-list-item-group multiple>
        <v-list-item active-class="EoleBlueLighten EoleBlue--text">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active" color="EoleBlue"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Notification de relance de pièce</v-list-item-title>
              <v-list-item-subtitle
                >Recevez une notification lorsque vous avez une pièce à fournir</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-if="active">mdi-bell</v-icon>
              <v-icon v-else>mdi-bell-off</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
        <v-list-item active-class="EoleBlueLighten EoleBlue--text">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active" color="EoleBlue"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Notification de relance de charge</v-list-item-title>
              <v-list-item-subtitle>Recevez une notification lorsqu'une charge arrive à échéance</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-if="active">mdi-bell</v-icon>
              <v-icon v-else>mdi-bell-off</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
        <v-list-item active-class="EoleBlueLighten EoleBlue--text">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active" color="EoleBlue"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Notification entretien annuel</v-list-item-title>
              <v-list-item-subtitle
                >Recevez une notification lorsque votre responsable à lancer la campagne d'entretien
                annuel</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-if="active">mdi-bell</v-icon>
              <v-icon v-else>mdi-bell-off</v-icon>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css"

export default {
  data: () => ({ email: null, phone: null }),
  created() {
    this.email = localStorage.getItem("email")
    // replace every 2 index add point and delete last point
    this.phone = localStorage
      .getItem("phone")
      .replace(/(\d{2})/g, "$1.")
      .slice(0, -1)
  },
}
</script>
<style lang="scss">
.no-padding {
  padding: 0;
}
</style>
