<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12">
          <v-card flat outlined class="rounded-xl h-full">
            <v-card-title style="font-size: 1.1rem">Informations Véhicule </v-card-title>
            <v-divider class="mt-n3"></v-divider>
            <v-card-text class="flex-grow-1 no-padding text-center">
              <v-card-text class="pa-5" v-if="vehicle">
                <v-row>
                  <v-col cols="12" md="12" class="text-left">
                    Marque :
                    <strong>{{ vehicle.brand }} </strong>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="text-left mt-n5">
                    Modèle :
                    <strong>{{ vehicle.model }} </strong>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="text-left mt-n5">
                    Nombre de chevaux fiscaux :
                    <strong>{{ vehicle.power }} cv</strong>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else> Aucun véhicle enregistré </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
export default {
  data() {
    return {
      vehicle: {},
    }
  },
  created() {
    this.getVehicle()
  },

  methods: {
    async getVehicle() {
      const idAccount = this.$store.state.user.id
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/vehicle`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        if (response.ok) {
          this.vehicle = data[0]

          console.log("Vehicle:", this.vehicle)
        }
      } catch (error) {
        console.error("Error:", error)
      }
    },
  },
}
</script>
