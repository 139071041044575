<template>
  <v-card>
    <v-card-text class="no-padding-x">
      <v-row>
        <v-col cols="12" md="6" class="d-flex">
          <v-card-text>
            <v-card flat outlined class="rounded-xl h-full">
              <v-card-title style="font-size: 1.1rem">Informations personnelles </v-card-title>
              <v-divider class="mt-n3"></v-divider>
              <v-card-text class="flex-grow-1 no-padding text-center">
                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" md="12" class="text-left">
                      N° de téléphone :
                      <strong> {{ formatPhoneNumber(user.phone_number) }}</strong>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Adresse email :
                      <strong> {{ user.personnal_email }}</strong>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Adresse postale :
                      <strong>
                        {{ user.street }}
                        {{ user.zip }} {{ user.city }}
                      </strong>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Situation familliale :
                      <strong>{{ user.marital_category }} </strong>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-card-text>
            <v-card flat outlined class="rounded-xl h-full">
              <v-card-title style="font-size: 1.1rem">Informations familliales </v-card-title>
              <v-divider class="mt-n3"></v-divider>
              <v-card-text class="flex-grow-1 no-padding text-center">
                <v-card-text class="pa-5">
                  <v-row
                    v-if="
                      user.id_account_marital_category == 2 ||
                      user.id_account_marital_category == 3 ||
                      user.id_account_marital_category == 4
                    "
                  >
                    <v-col cols="12" md="12" class="text-left">
                      Conjoint(e) :
                      <strong>{{ lawPartner.lastname }} {{ lawPartner.firstname }} </strong>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      :class="[
                        'text-left',
                        user.id_account_marital_category === 2 ||
                        user.id_account_marital_category === 3 ||
                        user.id_account_marital_category === 4
                          ? 'mt-n5'
                          : '',
                      ]"
                    >
                      Nombre d'enfant(s) :
                      <strong>{{ childCount }} </strong>
                    </v-col>
                  </v-row>
                  <v-row v-if="childCount > 0">
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Enfant(s) :
                      <span v-if="childCount === 0" class="EoleError--text">Aucun enfant repertorié</span>
                    </v-col>
                  </v-row>

                  <v-row v-for="child in childs" v-bind:key="child.id">
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      - {{ child.lastname }} {{ child.firstname }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col cols="12" md="12" class="text-left">
                      Contact d'urgence :
                      <span v-if="emergencyContact.length === 0" class="EoleError--text"
                        >Aucun contact d'urgence repertorié</span
                      >
                    </v-col>
                  </v-row>
                  <v-row v-for="contact in emergencyContact" v-bind:key="contact.id">
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      - {{ contact.lastname }} {{ contact.firstname }} :
                      <strong>{{ formatPhoneNumber(contact.phone_number) }}</strong>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="4" class="d-flex">
          <v-card-text>
            <!-- <v-card flat outlined class="rounded-xl h-full">
              <v-card-title style="font-size: 1.1rem">Informations Véhicule </v-card-title>
              <v-divider class="mt-n3"></v-divider>
              <v-card-text class="flex-grow-1 no-padding text-center">
                <v-card-text class="pa-5" v-if="selected.vehicle">
                  <v-row>
                    <v-col cols="12" md="12" class="text-left">
                      Marque :
                      <strong>{{ selected.vehicle.brand }} </strong>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Modèle :
                      <strong>{{ selected.vehicle.model }} </strong>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12" class="text-left mt-n5">
                      Nombre de chevaux fiscaux :
                      <strong>{{ selected.vehicle.power }} cv</strong>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else> Aucun véhicle enregistré pour {{ selected.firstname }} </v-card-text>
              </v-card-text>
            </v-card> -->
          </v-card-text>
        </v-col>
      </v-row>

      <v-list>
        <v-list-item-group>
          <v-list-item v-for="member in family" :key="member.id" :color="member.is_emergency == 1 ? 'EoleError' : null">
            <v-list-item-content>
              <v-list-item-title :class="member.is_emergency == 1 ? 'EoleError--text' : null"
                >{{ member.firstname }} {{ member.lastname }}
                <span v-if="member.is_emergency == 1">(Contact d'urgence)</span></v-list-item-title
              >
              <v-list-item-subtitle>{{ member.family_link_designation }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-actions v-if="!!member.phone_number">
              Téléphone : {{ formatPhoneNumber(member.phone_number) }}
            </v-list-item-actions>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
export default {
  data() {
    return {
      family: [],
      lawPartner: {},
      childs: [],
      childCount: 0,
      emergencyContact: [],
    }
  },
  async created() {
    this.fetchFamily()
  },
  methods: {
    async fetchFamily() {
      const idAccount = this.$store.state.user.id

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/family`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        if (response.ok) {
          this.family = data
        }
      } catch (error) {
        console.error("Error fetching family data", error)
      }

      let lawPartner = this.family.find(member => member.id_account_family_link == 1)
      this.lawPartner = lawPartner

      let childs = this.family.filter(member => member.id_account_family_link == 2)
      this.childs = childs

      this.childCount = childs.length

      let emergencyContact = this.family.filter(member => member.is_emergency == 1)
      this.emergencyContact = emergencyContact
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5")
    },
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped>
.no-padding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
