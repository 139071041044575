<template>
  <v-card outlined>
    <v-card-text class="profilBanner" style="height: 150px"> </v-card-text>
    <v-dialog v-model="dialogUpload" max-width="800px" persistent>
      <v-card flat>
        <v-card-title>
          <span class="text-h5">Modifier votre photo de profil</span>
        </v-card-title>
        <v-divider></v-divider>
        <Loading
          transition="fade"
          :active.sync="loadImage"
          :can-cancel="false"
          :is-full-page="false"
          loader="spinner"
          color="#efb639"
          :opacity="1"
        >
        </Loading>
        <v-card-text class="pa-5" v-if="document.data != null">
          <v-row>
            <v-col cols="12" sm="6" md="12">
              <v-card flat v-if="!!document.data" class="text-center align-center justify-center">
                <v-card-text class="pa-5">
                  <v-img
                    v-if="document.data.type === 'image\/jpeg' || document.data.type === 'image\/png'"
                    class="mx-auto"
                    max-width="200"
                    max-height="200"
                    :src="fileUrl"
                  ></v-img>
                  <v-card-text class="text-center mt-2">{{ document.data.name }} </v-card-text>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-card
            link
            :class="{ 'file-selected': fileSelected }"
            outlined
            @dragover.prevent
            v-model="document.data"
            @drop.prevent="onDrop"
            class="drop-zone flex d-flex flex-column justify-center text-center"
          >
            <v-card-text class="EoleBlue--text"
              ><v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
            </v-card-text>
            <v-card-text><small>Extension de fichier accepté : </small></v-card-text>
            <v-card-text class="EoleBlue--text"> .pdf / .png / .jpg</v-card-text>
            <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici</v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="EoleYellow"
            text
            @click="
              () => {
                dialogUpload = !dialogUpload
                document = {
                  name: null,
                  data: null,
                }
              }
            "
          >
            Annuler
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn class="white--text" color="EoleGreen" @click="uploadPicture">
            <v-icon left>mdi-file-plus</v-icon>
            Ajouter le document
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="profilBadge">
      <v-badge
        class="white--text"
        bottom
        :color="user.available === 0 ? `EoleError` : `EoleGreen`"
        :icon="user.available === 0 ? `mdi-minus` : `mdi-check`"
        overlap
        offset-x="30"
        offset-y="30"
      >
        <v-skeleton-loader v-if="loadImage" class="avatar-skeleton bordered" style="" type="image"></v-skeleton-loader>
        <v-avatar id="profil-picture" v-else-if="pictureProfil != null" class="avatar bordered" size="90">
          <v-img :src="pictureProfil"></v-img>
          <div class="hover-overlay" v-on:click="openFileExplorer">
            <span class="overlay-text">Changer votre photo</span>
          </div>
        </v-avatar>
        <v-avatar v-else class="avatar bordered" size="90" color="EoleBlue">
          <div class="hover-overlay" v-on:click="openFileExplorer">
            <span class="overlay-text">Changer votre photo</span>
          </div>
          <span class="white--text text-h4">{{ initial }}</span>
        </v-avatar>
      </v-badge>
    </div>

    <div class="text-center justify-center mt-10">
      <v-card-title class="text-h5">{{ user.firstname }} {{ user.lastname }} </v-card-title>
    </div>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-left">
          <span class="subtitle-2">Email :</span> <span>{{ user.email }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-col cols="12" md="12" class="text-left">
          <span class="subtitle-2">Agence :</span> <span>{{ user.agency_name }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-col cols="12" md="12" class="text-left">
          <span class="subtitle-2">Numéro de téléphone :</span> <span>{{ user.phone_number }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-col cols="12" md="12" class="text-left">
          <span class="subtitle-2">Télétravail :</span>
          <v-icon v-if="user.is_telework == 1" color="EoleGreen">mdi-check</v-icon>
          <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-col cols="12" md="12" class="text-left">
          <span class="subtitle-2">Affilié à la mutuelle d'entreprise :</span>
          <v-icon v-if="user.is_affiliated_mutual == 1" color="EoleGreen">mdi-check</v-icon>
          <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-center">
      <v-btn class="white--text" color="EoleGreen" v-if="user.available === 0" @click="updateAvailability(1)"
        >Me rendre de nouveau disponible</v-btn
      >
      <v-btn class="white--text" color="EoleError" v-else @click="updateAvailability(0)">Me rendre indisponible</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js"
import "@mdi/font/css/materialdesignicons.css" // Load icons
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

import { ref } from "@vue/composition-api"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadImage: false,
    dialogUpload: false,
    document: {
      name: null,
      data: null,
      fileId: null,
    },
    fileUrl: null,
    fileSelected: null,
    loader: null,
    loadingAccountVerify: false,
    phone: null,
    editData: false,
    inputModify: true,
    agency: null,
    email: null,
    verified: 0,
    availability: null,
    lastname: null,
    firstname: null,
    initial: null,
    loadingData: false,
    validityFormPassword: true,
    waitTimeout: false,
    password: "",
    passwordValidation: "",
    passwordErrorMessage: "",
    pictureProfil: null,
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 8 || "Minimum 8 caractères",
    },
  }),
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
  },
  components: {
    Loading,
  },
  created() {
    this.initial = this.user.firstname.charAt(0) + this.user.lastname.charAt(0)
    this.getPictureProfil()
  },

  methods: {
    openFileExplorer() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/png, image/jpeg"
      input.addEventListener("change", this.uploadPicture)
      input.click()
    },
    async uploadPicture() {
      const file = event.target.files[0]
      this.loadImage = true
      this.document.data = file
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "image/png":
          case "image/jpeg":
            const id = localStorage.getItem("user_id")
            const username = localStorage.getItem("complete_name").replace(" ", "-")
            const formData = new FormData()
            formData.append("document", this.document.data)
            formData.append("userId", id)
            formData.append("documentName", "profil")
            formData.append("categorieId", 4)
            formData.append("username", username.toLowerCase().replace(" ", ""))
            formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
            formData.append("place", "account")

            try {
              fetch(`${config.apiUri}/upload`, {
                method: "POST",
                body: formData,
              })
                .then(res => {
                  if (res.ok) {
                    this.dialogUpload = false
                    this.document = {
                      name: "",
                      data: null,
                      categorie: null,
                    }
                  } else {
                    alert("Erreur lors de l'upload de l'image")
                  }
                })
                .finally(() => {
                  setTimeout(() => {
                    // location.reload()
                    this.loadImage = false
                    this.getPictureProfil()
                  }, 2000)
                })
            } catch (err) {
              console.error(err)
              alert("Erreur lors de l'upload de l'image")
            }

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    onDrop(e) {
      e.preventDefault()
      this.document.data = e.dataTransfer.files[0]
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileSelected = true
            // this.renderPdf(this.document.data)

            // view pdf in iframe

            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          case "image/png":
          case "image/jpeg":
            this.fileSelected = true
            this.fileUrl = URL.createObjectURL(this.document.data)
            this.dialogUpload = true

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    getPictureProfil() {
      try {
        let completeName = `${this.$store.state.user.lastname} ${this.$store.state.user.firstname}`
        let name = completeName.toLowerCase().replace(/ /g, "-")
        let agency = this.$store.state.user.agency_name.toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        let pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        let img = new Image()
        img.onload = () => {
          this.pictureProfil = img.src
        }
        img.onerror = () => {
          this.pictureProfil = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.pictureProfil == null) {
            img.src = pictureProfilJpg
          }
        }, 500)
      } catch (error) {
        this.pictureProfil = null
      }

      // return url with picture profil
    },
    validateFormPassword() {
      if (this.password.length >= 8 && this.password === this.passwordValidation) {
        if (this.$refs.formPassword.validate()) {
          this.passwordErrorMessage = ""

          this.updatePassword()

          this.password = ""
          this.passwordValidation = ""
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },

    async updatePassword() {
      let idUser = localStorage.getItem("user_id")
      this.waitTimeout = true
      // await pause(3000)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response

        if (this.password === this.passwordValidation) {
          if (localStorage.getItem("linked") != null) {
            response = await fetch(
              `${config.apiUri}/php/pages/accounts.php?id=${idUser}&changePassword=true&newPassword=${this.password}`,
              {
                mode: "cors",
                method: "PUT",
                headers: headers,
              },
            )
          } else {
            response = await fetch(`${config.apiUri}/php/pages/users.php?id=${idUser}&newPassword=${this.password}`, {
              mode: "cors",
              method: "PUT",
              headers: headers,
            })
          }
        }

        this.$toast.info(`Vous venez de modifier votre mot de passe avec succès.`, {
          position: "bottom-right",
          timeout: 5000,
        })

        setTimeout(() => {
          this.logOut()
        }, 5000)
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    async updateAvailability(idAvailability) {
      let idAccount = this.$store.state.user.id

      try {
        let response = await fetch(`${config.apiUri}/accounts/${idAccount}/set-availability`, {
          mode: "cors",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({ availability: idAvailability }),
        })

        if (response.ok) {
          // window.location.reload()

          //reload the page

          window.location.reload()

          this.$toast.info(`Vous venez de modifier votre disponibilité avec succès.`, {
            position: "bottom-right",
            timeout: 5000,
          })
        } else {
          this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },

    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
      var match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/)
      if (match) {
        return match[1] + " " + match[2] + " " + match[3] + " " + match[4] + " " + match[5]
      }
      return null
    },
    onFileChange(item, e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(item, files[0])
    },
    createImage(item, file) {
      var image = new Image()
      var reader = new FileReader()

      reader.onload = e => {
        item.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    removeImage: function (item) {
      item.image = false
    },
    update() {
      this.inputModify = false
      this.editData = true
    },
    reset() {
      this.inputModify = true
      this.editData = false
    },
  },
  mounted() {
    //console.log("Enfant monté")
  },
}
</script>
<style lang="scss">
.profilBadge .v-badge__badge {
  height: 1.75rem !important;
  width: 1.75rem !important;
  // round
  border-radius: 50% !important;
  // text centered
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  // icon size
  font-size: 1rem !important;
  color: white !important;

  .v-icon {
    color: white !important;
  }
}
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (max-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 0;
    width: 0%;
  }
}

.profilBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.no-padding-bottom {
  padding: 0 !important;
}

.hover-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}

.avatar:hover .hover-overlay {
  opacity: 1;
}

.avatar-skeleton {
  width: 100%;
  height: 100%;
  // round
  border-radius: 50%;
}

.overlay-text {
  color: white;
  font-size: 10px !important;
  text-align: center;
}

.bordered {
  border: 1.5px solid #e0e0e0;
}

// .badge {
//   height: 90px !important;
//   width: 90px !important;
//   z-index: 999;
// }
</style>
<style lang="sass">
$badge-height: 1.5rem
</style>
